<template>
  <b-card no-body>
    <b-card-header>
      <h4>Bilty</h4>
    </b-card-header>

    <b-card-body>
      <bilty-filters
        @filtersUpdated="filtersChangeHandler"
      />

      <bilty-list
        :filters="filters"
      />

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'

import BiltyFilters from '@/components/Invoices/Bilty/BiltyFilters.vue'
import BiltyList from '@/components/Invoices/Bilty/BiltyList.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BiltyFilters,
    BiltyList,
  },
  data() {
    return {
      filters: {},
    }
  },
  methods: {
    filtersChangeHandler(filterData) {
      this.filters = filterData
    },
  },
}
</script>
