<template>
  <b-modal
    v-model="modalShow"
    size="xl"
    scrollable
    title="Bilty Details"
    @hidden="$emit('modal-closed')"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <div v-if="!loading && !loadingError">
      <bilty-info
        :item="biltyDetails"
      />

      <div class="d-flex justify-content-between align-items-center">
        <h6>Invoices</h6>
        <div class="my-1">
          <b-button
            variant="outline-primary"
            @click="addInvoice"
          >
            Add Invoice
          </b-button>
        </div>
      </div>

      <invoice-list
        :bilty-id="biltyId"
        @edit-invoice="editInvoice"
      />
    </div>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="secondary"
          class="float-right"
          @click="modalShow=false"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton, BAlert, BSpinner,
} from 'bootstrap-vue'
import axios from 'axios'

import InvoiceList from '../Invoice/InvoiceList.vue'
import BiltyInfo from './BiltyInfo.vue'

export default {
  components: {
    BButton,
    BAlert,
    BSpinner,
    InvoiceList,
    BiltyInfo,
  },

  props: {
    biltyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      biltyDetails: null,
      modalShow: true,
      loadingError: null,
      loading: true,
    }
  },
  created() {
    this.fetchDetails()
  },
  methods: {
    fetchDetails() {
      axios.get(`/invoices/bilty/${this.biltyId}/`)
        .then(res => {
          this.biltyDetails = res.data
          this.loadingError = null
          this.loading = false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.detail || 'Error fetching bilty'
          this.loading = false
        })
    },
    addInvoice() {
      this.$emit('add-invoice', this.biltyId)
      this.modalShow = false
    },
    editInvoice(data) {
      this.$emit('edit-invoice', data)
      this.modalShow = false
    },
  },
}
</script>
