<template>
  <div>

    <b-row>
      <b-col
        align="right"
      >
        <b-button
          variant="outline-primary"
          class="mb-1"
          @click="createBilty = true"
        >
          Add Bilty
        </b-button>
      </b-col>
    </b-row>

    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <b-table
      v-if="!loading && !loadingError"
      :items="results"
      :fields="tableFields"
      responsive="sm"
      striped
      hover
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="compact-table"
      no-local-sorting
      @sort-changed="sortingChanged"
    >
      <template #cell(date)="data">
        <span v-if="data.item.date">
          {{ formatedDate(data.item.date) }}
        </span>
      </template>

      <template #cell(received_date)="data">
        <span v-if="data.item.received_date">
          {{ formatedDate(data.item.received_date) }}
        </span>
      </template>

      <template #cell(actions)="data">
        <feather-icon
          v-b-tooltip.hover="{boundary:'window'}"
          title="View Details"
          icon="EyeIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="displayBiltyDetailsId = data.item.id"
        />
        <feather-icon
          v-b-tooltip.hover="{boundary:'window'}"
          title="Edit Bilty"
          icon="EditIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="editBiltyId = data.item.id"
        />
      </template>
    </b-table>

    <detailed-pagination
      v-if="!loading && !loadingError"
      :total-items="totalItems"
      :per-page="perPage"
      :current-page="currentPage"
      @page-changed="(page) => {currentPage = page}"
    />

    <bilty-form
      v-if="createBilty"
      @modal-closed="createBilty = false"
      @saved="fetchItems"
    />

    <bilty-form
      v-if="editBiltyId"
      :bilty-id="editBiltyId"
      @modal-closed="editBiltyId = null"
      @saved="fetchItems"
    />

    <bilty-details
      v-if="displayBiltyDetailsId"
      :bilty-id="displayBiltyDetailsId"
      @modal-closed="displayBiltyDetailsId = null"
      @add-invoice="addInvoice"
      @edit-invoice="editInvoice"
    />

    <invoice-form
      v-if="addInvoiceDetails"
      :bilty-id="addInvoiceDetails.biltyId"
      @modal-closed="closeInvoiceForm"
    />

    <invoice-form
      v-if="editInvoiceDetails"
      :bilty-id="editInvoiceDetails.biltyId"
      :invoice-id="editInvoiceDetails.invoiceId"
      @modal-closed="closeInvoiceForm"
    />

  </div>
</template>

<script>
import {
  BSpinner, BTable, BAlert, BButton, VBTooltip, BRow, BCol,
} from 'bootstrap-vue'
import axios from 'axios'

import dateMixin from '@/mixins/dateMixin'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
import BiltyForm from '@/components/Invoices/Bilty/BiltyForm.vue'
import BiltyDetails from '@/components/Invoices/Bilty/BiltyDetails.vue'
import InvoiceForm from '@/components/Invoices/Invoice/InvoiceForm.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BTable,
    BAlert,
    BButton,
    BRow,
    BCol,
    DetailedPagination,
    BiltyForm,
    BiltyDetails,
    InvoiceForm,
  },
  mixins: [dateMixin],
  props: {
    filters: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      createBilty: false,
      editBiltyId: null,
      displayBiltyDetailsId: null,
      loading: true,
      loadingError: null,
      results: [],
      tableFields: [
        { key: 'bilty_number', label: 'BILTY NO.', sortable: true },
        { key: 'date', label: 'DATE', sortable: true },
        { key: 'lot_size', label: 'LOT SIZE', sortable: true },
        { key: 'transporter__name', label: 'TRANSPORTER', sortable: true },
        { key: 'received_date', label: 'RECEIVED DATE', sortable: true },
        { key: 'created_by__fullname', label: 'CREATED BY', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 0,
      sortBy: 'id',
      sortDesc: true,
      addInvoiceDetails: null,
      editInvoiceDetails: null,
    }
  },
  watch: {
    currentPage() {
      this.fetchItems()
    },
    filters() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.fetchItems()
      }
    },
  },
  created() {
    this.fetchItems()
  },
  methods: {
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchItems()
    },
    fetchItems() {
      this.loading = true
      axios.post('/invoices/filtered_bilties', {
        page: this.currentPage,
        per_page: this.perPage,
        sort_by: this.sortBy,
        sort_desc: this.sortDesc,
        filters: { ...this.filters },
      })
        .then(res => {
          this.results = res.data.results
          this.totalItems = res.data.count
          this.loadingError = null
          this.loading = false
        }).catch(error => {
          this.loading = false
          const errorResponse = error?.response
          if (errorResponse && errorResponse.status === 404 && this.currentPage > 1) {
            this.currentPage -= 1
            this.fetchItems()
          } else {
            this.loadingError = error?.response?.data?.detail || 'Error fetching bilty list'
          }
        })
    },
    addInvoice(biltyId) {
      this.addInvoiceDetails = {
        biltyId,
      }
    },
    editInvoice({ invoiceId, biltyId }) {
      this.editInvoiceDetails = {
        invoiceId,
        biltyId,
      }
    },
    closeInvoiceForm() {
      if (this.addInvoiceDetails) {
        this.displayBiltyDetailsId = this.addInvoiceDetails.biltyId
        this.addInvoiceDetails = null
      } else {
        this.displayBiltyDetailsId = this.editInvoiceDetails.biltyId
        this.editInvoiceDetails = null
      }
    },
  },
}
</script>
