<template>
  <b-modal
    v-model="showModal"
    :title="`${biltyId ? 'Edit' : 'Add'} Bilty`"
    size="lg"
    class="m-4"
    no-close-on-backdrop
    @hidden="$emit('modal-closed')"
    @ok="submit"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <b-form
      v-if="!loading && !loadingError"
      @submit.prevent="submit"
    >
      <validation-observer
        ref="biltyForm"
      >
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Bilty No."
              vid="bilty_number"
              rules="required"
            >
              <b-form-group label="Bilty No.">
                <b-form-input
                  v-model="biltyNumber"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Date"
              vid="date"
              rules="required"
            >
              <b-form-group label="Date">
                <b-form-datepicker
                  v-model="date"
                  menu-class="w-100"
                  calendar-width="100%"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Lot Size"
              vid="lot_size"
              rules="required"
            >
              <b-form-group label="Lot Size">
                <b-form-input
                  v-model="lotSize"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Transporter"
              vid="transporter"
              rules="required"
            >
              <b-form-group
                label="Transporter"
                :state="errors.length > 0 ? false : null"
              >
                <custom-v-select
                  v-model="transporter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="transporterOptions"
                  label="text"
                  placeholder="Select Transporter"
                  :reduce="option => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Received Date"
              vid="received_date"
              rules="required"
            >
              <b-form-group label="Received Date">
                <b-form-datepicker
                  v-model="receivedDate"
                  menu-class="w-100"
                  calendar-width="100%"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Received By"
              vid="received_by"
              rules="required"
            >
              <b-form-group
                label="Received By"
                :state="errors.length > 0 ? false : null"
              >
                <custom-v-select
                  v-model="receivedBy"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="salesmanOptions"
                  label="text"
                  placeholder="Select salesman"
                  :reduce="option => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Cost Per Carton"
              vid="cost_per_carton"
              rules="required"
            >
              <b-form-group
                label="Cost Per Carton"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input
                  v-model="costPerCarton"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="To Pay Charge"
            >
              <b-form-input
                v-model="toPayCharge"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Cost"
            >
              <b-form-input
                v-model="cost"
                type="number"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>
    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="saving || loading || loadingError ? true : false"
        @click="ok()"
      >
        Submit
        <b-spinner
          v-if="saving"
          small
          label="Small Spinner"
        />
      </b-button>

    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BButton, BFormInput, BFormDatepicker, BForm, BSpinner, BAlert,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dateMixin from '@/mixins/dateMixin'

export default {
  components: {
    CustomVSelect,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormDatepicker,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BAlert,
  },
  mixins: [dateMixin],
  props: {
    biltyId: {
      type: Number,
      required: false,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      showModal: true,
      biltyNumber: null,
      date: null,
      lotSize: null,
      transporter: null,
      receivedDate: null,
      transporterOptions: [],
      loading: false,
      loadingError: null,
      saving: false,
      receivedBy: null,
      salesmanOptions: [],
      costPerCarton: null,
      toPayCharge: null,
    }
  },
  computed: {
    cost() {
      if (this.lotSize && this.costPerCarton) {
        return this.lotSize * this.costPerCarton
      }
      return null
    },
  },
  created() {
    this.initializeForm()
  },
  methods: {
    async initializeForm() {
      this.loading = true
      try {
        const res = await axios.get('/returns/courier_options')
        this.transporterOptions = res.data.data
        const { data } = await axios.get('/accounts/salesmans_options')
        this.salesmanOptions = data.data
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Error fetching dropdown options'
        this.loading = false
        return
      }

      let bilty
      if (this.biltyId) {
        try {
          const res = await axios.get(`/invoices/bilty/${this.biltyId}/`)
          bilty = res.data
        } catch (error) {
          this.loadingError = error?.response?.data?.detail || 'Error fetching bilty'
          this.loading = false
          return
        }
      }

      if (this.biltyId) {
        this.biltyNumber = bilty.bilty_number
        this.date = bilty.date
        this.lotSize = bilty.lot_size
        this.transporter = bilty.transporter
        this.receivedDate = bilty.received_date
        this.receivedBy = bilty.received_by || null
        this.costPerCarton = bilty.cost_per_carton || null
        this.toPayCharge = bilty.to_pay_charge || null
      } else {
        const currentUTCTime = moment().utc().format('YYYY-MM-DD HH:mm:ss')
        const receivedDate = this.formatedDate(currentUTCTime, 'YYYY-MM-DD')

        this.biltyNumber = null
        this.date = null
        this.lotSize = null
        this.transporter = null
        this.receivedDate = receivedDate
      }

      this.loading = false
    },
    async submit(event) {
      event.preventDefault()

      const isFormValid = await this.$refs.biltyForm.validate()
      if (!isFormValid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please correct the form errors',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      this.saving = true

      const formData = {
        bilty_number: this.biltyNumber,
        date: this.date,
        lot_size: this.lotSize,
        received_date: this.receivedDate,
        transporter: this.transporter,
        received_by: this.receivedBy,
        cost_per_carton: this.costPerCarton,
        to_pay_charge: this.toPayCharge || null,
      }
      let operation
      let message
      if (this.biltyId) {
        operation = axios.patch(`/invoices/bilty/${this.biltyId}/`, formData)
        message = 'Bilty updated successfully'
      } else {
        operation = axios.post('/invoices/bilty/', formData)
        message = 'Bilty created successfully'
      }

      operation
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$refs.biltyForm.reset()
          this.saving = false
          this.showModal = false
          this.$emit('saved')
        }).catch(error => {
          this.$refs.biltyForm.reset()

          const statusCode = error?.response?.status
          const serverErrors = error?.response?.data
          if (statusCode === 400 && serverErrors) {
            this.$refs.biltyForm.setErrors(serverErrors)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Please correct the form errors',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.response?.data?.detail || 'Error saving bilty',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
          this.saving = false
        })
    },
  },
}

</script>
