<template>
  <b-row>
    <b-col md="2">
      <b-form-group
        label="Bilty Number"
      >
        <b-form-input
          v-model="biltyNumber"
          type="text"
          placeholder="Search Bilty Number"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="3">
      <b-form-group
        label="Date Range"
      >
        <flat-picker
          v-model="dateRange"
          placeholder="Select Date Range"
          :config="{ mode: 'range'}"
        />
      </b-form-group>
    </b-col>

    <b-col md="3">
      <b-form-group
        label="Transporter"
      >
        <custom-v-select
          v-model="selectedTransporters"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="transporterOptions"
          label="text"
          placeholder="Select Transporters"
          multiple
          :close-on-select="false"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>

  </b-row>
</template>

<script>
import {
  VBTooltip, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    FlatPicker,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    CustomVSelect,
  },
  data() {
    return {
      biltyNumber: null,
      dateRange: null,
      transporterOptions: [],
      selectedTransporters: [],
    }
  },
  watch: {
    dateRange(newValue) {
      if (newValue) {
        const [fromDate, toDate] = newValue.split(' to ')
        if (fromDate && toDate) {
          this.filtersChangeHandler()
        }
      } else if (newValue === '') {
        this.filtersChangeHandler()
      }
    },
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      axios.get('/returns/courier_options')
        .then(res => {
          this.transporterOptions = res.data.data
        })
    },
    filtersChangeHandler() {
      const filtersdata = {}
      if (this.selectedTransporters.length > 0) {
        filtersdata.transporters = this.selectedTransporters.map(item => item.id)
      }
      if (this.dateRange) {
        const [dateFrom, dateTo] = this.dateRange.split(' to ')
        if (dateFrom && dateTo) {
          filtersdata.date_from = dateFrom
          filtersdata.date_to = dateTo
        }
      }
      if (this.biltyNumber) {
        filtersdata.bilty_number = this.biltyNumber
      }
      this.$emit('filtersUpdated', filtersdata)
    },
  },

}
</script>
